import { Button, Col, Form, message, Row, Typography } from 'antd';
import { useForm } from '../../utils/hooks/useForm';
import FloatInput from '../../components/FloatInput/floatInput';
import { useAuth } from '../../utils/hooks/useAuth';
import './verifyOTP.modules.css'
import { useLocation, useNavigate } from 'react-router-dom';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useCountDown } from '../../utils/hooks/useCountDown';
import { useState } from 'react';
import { allowNumOnly } from '../../utils/methods/form-validations';

const VerifyOTPV1 = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { form } = useForm();
  const { handleVerifyOTP, handleResendOtp, isLoading, isApiLoading } = useAuth(form);
  const { count, resetCount } = useCountDown(60);
  const [isResetCode, setIsResetCode] = useState(false);

  const handleResetCode = () => {
    resetCount();
    setIsResetCode(true);
    form?.submit();
  }

  const onFinish = async (values: { otp: string }) => {
    try {
      if (isResetCode) {
        await handleResendOtp(location.state.phoneNumber || "", location.state.email || "", resetCount);
        return;
      }
      await handleVerifyOTP({ username: location.state.email, password: location.state.password, otp: values.otp.toString(), isUpdatingUser: !!location.state?.updatingUserPhone })
    } catch (error) {
      message.error(`${error}`)
    }
    finally {
      setIsResetCode(false);
    }
  }

  return (
    <div className='my-2 flex flex-col justify-center items-center px-6 py-4 w-full max-w-screen-2xl mx-auto h-[90vh]'>
      <Form
        form={form}
        name='signup'
        onFinish={onFinish}
        className='my-6 pt-2 flex flex-col bg-custom-gradient w-full max-w-[470px] justify-center items-center rounded-xl mobile-m:rounded-3xl px-2 mobile-m:px-4'
      >
        <div className='flex flex-col justify-center items-center max-w-[380px] w-full'>
          <div className='w-full flex justify-between items-center'>
            <Button className='bg-transparent border-none text-[#A8BDC1] rounded-[42px] p-4 hover:bg-[#e8f3ff] hover:text-[#7e5aeb]' icon={<ArrowLeftOutlined />} onClick={() => navigate('/login-new')} />
            <Typography.Text className='w-full mr-8 font-urbanist text-center text-3xl text-[#0F0F1C] font-semibold'>{"Verify OTP"}</Typography.Text>
          </div>
          <Typography.Text className='m-4 font-urbanist text-center leading-4 text-[12px] text-normal text-[#5d6769] mobile-m:text-sm'>
            Please verify your Phonenumber with OTP Below
          </Typography.Text>

          <Row gutter={[12, 12]} className='w-full justify-center items-center'>
            <Col span={24}>
              <Form.Item
                name="otp"
                rules={[
                  { ...(!isResetCode && { required: true, message: 'Please enter the OTP.' }) },
                  { pattern: /^\d{6}$/, message: 'OTP must be a 6-digit number.' },
                ]}
              >
                <FloatInput
                  type='text'
                  placeholder="*Enter 6-digit OTP"
                  maxLength={6}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => allowNumOnly(e, form, "otp")}
                  controls={false}
                  onPressEnter={() => form.submit()}
                />
              </Form.Item>
            </Col>
            <div className='flex flex-col-reverse justify-center items-center mb-3 mobile-lg:flex-row gap-x-4'>
              {!!count ? <Typography.Text
                className='font-urbanist text-[#7e5ae2] font-semibold'
              >
                Resend OTP in {count}
              </Typography.Text> : <Button
                className='font-urbanist text-[#7e5ae2] font-semibold' type='link' block onClick={handleResetCode}>Resend OTP</Button>}
              <Button
                type="primary"
                htmlType={"submit"}
                loading={isLoading || isApiLoading}
                className={`font-urbanist font-semibold bg-[#7e5ae2]`}
              >
                Verify Phone Number
              </Button>
            </div>
          </Row>
        </div>
      </Form>
    </div>
  )
}

export default VerifyOTPV1;