import { useState } from 'react';
import SideNav from './components/SideNav/sideNav';
import { Layout } from 'antd';
import { RoutesList } from './components/routes';
import { useAuth } from './utils/hooks/useAuth';
import AppHeader from './components/AdvisoryHeader/AdvisoryHeader';
import { ScrollToTop } from './components/ScrollToTop';

const App = () => {
  const { isAuthenticated } = useAuth();
  const [background, setBackgroundImage] = useState<any>({ background: 'white' });

  return (
    <Layout style={{ maxHeight: '100svh', ...(!isAuthenticated && { overflowY: 'hidden' }) }}>
      {isAuthenticated && window.location.href.split("/").pop() !== "verify-otp" && <AppHeader />}
      <Layout
        className="inner-layout custom-scroller"
        style={{
          padding: '0 0',
          overflowX: 'hidden',
          height: !isAuthenticated ? 'calc(100svh - 64px)' : "100svh",
          flexDirection: 'row',
          alignItems: 'baseline',
          ...background,
        }}
      >
        <ScrollToTop />
        {isAuthenticated && window.location.href.split("/").pop() !== "verify-otp" && <SideNav />}
        <RoutesList setBackgroundImage={setBackgroundImage} />
      </Layout>
    </Layout>
  );
};

export default App;