import { useEffect } from 'react';

export function ScrollToTop() {

  useEffect(() => {
    const customScrollerRef = document.querySelector(".custom-scroller");
    if (customScrollerRef) {
      customScrollerRef.scrollTo({ top: 0, behavior: "smooth" })
    }
  }, [window.location.href]);

  return null;
}