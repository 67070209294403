import { StoreValue } from "antd/es/form/interface";
import useMakeApiRequest from "../../api/useMakeApiRequest";
import { IQuestionPayload } from "../../types/global.types";
import { FormInstance, message } from "antd";

export const useQuestion = () => {
  const { handleApiRequest, makeApiRequest, isApiLoading } =
    useMakeApiRequest();

  const postQuestion = (values: StoreValue, form: FormInstance) => {
    handleApiRequest(
      () =>
        makeApiRequest(
          "/user/send-email",
          "POST",
          {},
          {
            name: values.questions_name,
            npi_number: values.questions_npiNumber,
            email_address: values.questions_email,
            question: values.questions_question,
            phone_number: `+${values.questions_phonenumber}`,
          }
        ),
      (response: any) => {
        if (response.status === 200) {
          message.success(response.message);
          form.resetFields();
        }
      },
      "Unable to Send Question!",
      true
    );
  };

  return {
    isApiLoading,
    postQuestion,
  };
};
