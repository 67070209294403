import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState, useCallback, useMemo } from "react";
import useMakeApiRequest from "../../api/useMakeApiRequest";
import { useNavigate } from "react-router-dom";
import { useEncryption } from "../../utils/hooks/useEncryption";
import { authActions } from "../../store/reducers/userReducer";
import { RootState } from "../../store/store";
import { FormInstance, message } from "antd";
import useDashboard from "../../Pages/AdvisoryBoardPortal/hooks/useDashboard";

export const useAuth = (form?: FormInstance) => {
  const { makeApiRequest, isApiLoading, handleApiRequest } =
    useMakeApiRequest();
  const { updateUserDetails } = useDashboard(form);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { encryptData, decryptData } = useEncryption();
  const [isLoading, setLoading] = useState(false);
  const [verifiedForgetPassEmail, setVerifiedForgetPassEmail] = useState("");
  const authUser = useSelector((state: RootState) => state.auth.authUser);
  const userDetailPayload = useSelector(
    (state: RootState) => state.userDetail.userDetail
  );
  const isAuthenticated = useMemo(() => !!authUser?.idToken, [authUser]);

  useEffect(() => {
    const changedEmail = form?.getFieldValue("email");
    const changedPassword = form?.getFieldValue("password");
    if (
      authUser?.email &&
      authUser?.password &&
      authUser?.rememberMe &&
      !changedEmail &&
      !changedPassword
    ) {
      form?.setFieldsValue({
        email: authUser?.email,
        password: decryptData(authUser.password),
        remember: !!authUser.rememberMe,
      });
    }
  }, [authUser, form, decryptData]);

  const handleResendOtp = async (
    phoneNumber: string,
    email: string,
    resetCount: Function
  ) => {
    handleApiRequest(
      () => resendPhoneOtp(phoneNumber, email),
      (response: any) => {
        if (response?.status === 200) {
          message.success(response.message);
        }
        resetCount();
      },
      "Failed to Resend OTP",
      false
    );
  };

  const submitLogin = useCallback(
    ({
      email,
      password,
      remember,
    }: {
      email: string;
      password: string;
      remember: boolean;
    }) => {
      dispatch(
        authActions.setAuthCredentials({
          email,
          password: encryptData(password),
          rememberMe: remember,
        })
      );
    },
    [dispatch, encryptData]
  );

  const resendPhoneOtp = useCallback(
    async (phoneNumber: string, email?: string) => {
      return await makeApiRequest(
        "/user/resend-phone-verification",
        "POST",
        {},
        { userName: email ?? authUser?.email, phoneNumber }
      );
    },
    [makeApiRequest, authUser]
  );

  const handleVerifyOTP = async (values: {
    username: string;
    password: string | undefined;
    otp: string;
    isUpdatingUser?: boolean;
  }) => {
    setLoading(true);
    try {
      const result = await makeApiRequest(
        "/auth/verify-phone",
        "POST",
        {},
        {
          username: values.username,
          code: values.otp,
        },
        "application/json",
        true
      );

      if (result.error) {
        message.error(result.error);
      } else {
        message.success(
          result.message || "Phone number verified successfully!"
        );
        if (values?.isUpdatingUser) {
          updateUserDetails(userDetailPayload);
          navigate("/");
        } else {
          navigate("/login-new");
        }
      }
    } catch (error) {
      message.warning("Verification failed. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const sendResetPassCode = ({
    email,
    resetCount,
    isLoadingState,
  }: {
    email: string;
    resetCount: Function;
    isLoadingState: boolean;
  }) => {
    handleApiRequest(
      () => makeApiRequest("/user/forgot-password", "POST", {}, { email }),
      (verifyEmail: any) => {
        message.success(verifyEmail.message);
        resetCount();
        form?.resetFields();
        setVerifiedForgetPassEmail(email);
      },
      "Failed to send reset code!",
      isLoadingState
    );
  };

  const forgetPassword = async ({
    email,
    password,
    verificationCode,
    resetCount,
    isLoadingState,
  }: {
    email: string;
    password: string;
    verificationCode: string;
    resetCount: Function;
    isLoadingState: boolean;
  }) => {
    if (verifiedForgetPassEmail) {
      handleApiRequest(
        () =>
          makeApiRequest(
            "/user/confirm-forgot-password",
            "POST",
            {},
            {
              email: verifiedForgetPassEmail,
              newPassword: password,
              verificationCode,
            }
          ),
        (updatePassword: any) => {
          message.success(updatePassword.message);
          form?.resetFields();
          navigate("/login-new");
        },
        "Password reset Failed!",
        true
      );
    } else {
      sendResetPassCode({ email, resetCount, isLoadingState });
    }
  };

  const signin = async (values: {
    email: string;
    password: string;
    remember: boolean;
  }) => {
    setLoading(true);
    try {
      const loginInfo = await makeApiRequest(
        "/user/login",
        "POST",
        {},
        { email: values.email, password: values.password }
      );

      if (loginInfo?.response?.data?.error) {
        form?.setFields([
          { name: "email", errors: [loginInfo.response.data.error] },
        ]);
      } else if (loginInfo?.phoneNumber) {
        message.warning(loginInfo?.error);
        const response = await resendPhoneOtp(
          loginInfo.phoneNumber,
          values.email
        );
        if (response?.status === 200) {
          message.success(response.message);
          dispatch(
            authActions.setAuthCredentials({
              email: values.email,
              phoneNumber: loginInfo.phoneNumber,
              password: encryptData(values.password),
              rememberMe: !!values.remember,
            })
          );
          navigate("/verify-otp", {
            state: {
              email: values.email,
              password: values.password,
              phoneNumber: loginInfo.phoneNumber,
            },
          });
        } else if (response?.message) {
          message.warning(response.message);
        }
      } else if (loginInfo?.data?.AuthenticationResult) {
        message.success(loginInfo?.message);
        const authInfo = loginInfo.data.AuthenticationResult;
        dispatch(
          authActions.setAuthTokens({
            email: values.email,
            accessToken: authInfo.AccessToken,
            idToken: authInfo.IdToken,
            refreshToken: authInfo.RefreshToken,
            expiresIn: authInfo.ExpiresIn,
            tokenType: authInfo.TokenType,
            rememberMe: values.remember,
          })
        );
        submitLogin(values);
        navigate("/login-new");
      } else {
        form?.setFields([
          {
            name: "email",
            errors: [
              loginInfo?.error || "Server Error. Please try again later.",
            ],
          },
        ]);
      }
    } catch (error: any) {
      message.warning(error?.response?.data?.message || "Login failed");
    } finally {
      setLoading(false);
    }
  };

  const signup = async (payload: any) => {
    setLoading(true);
    try {
      const formData = new FormData();
      Object.entries({
        first_name: payload.firstName,
        last_name: payload.lastName,
        email: payload.email,
        phone_number: `+${payload.phoneNumber}`,
        password: payload.password,
        npi: payload.npiNumber,
        street: payload.address,
        city: payload.city,
        state: payload.state,
        zip_code: payload.zipCode,
        gender: payload.gender,
        dea_number: payload.deaNumber,
        image: payload.passportID,
        hipaa_signature: payload.signatureFile,
      }).forEach(([key, value]) => formData.append(key, value));

      const result = await makeApiRequest(
        "/auth/signup",
        "POST",
        {},
        formData,
        "multipart/form-data",
        true
      );

      if (result?.error) {
        message.warning(result.error);
      } else {
        const { email, password, phoneNumber } = payload;
        authActions.setAuthCredentials({ email, password, phoneNumber });

        if (result?.status === 201) {
          message.success(result.message);
          navigate("/verify-otp", { state: { email, password, phoneNumber } });
          form?.resetFields();
        } else if (result?.message) {
          message.warning(result.message);
        }
      }
    } catch (error: any) {
      const renderMessage = error?.response?.response?.data?.message ?? "";
      renderMessage && message.warning(renderMessage);
    } finally {
      setLoading(false);
    }
  };

  const logout = () => {
    dispatch(authActions.logout());
    navigate("/login-new");
    message.success("Logged out Successfully!");
  };

  return {
    isLoading,
    isAuthenticated,
    verifiedForgetPassEmail,
    signin,
    logout,
    signup,
    handleVerifyOTP,
    resendPhoneOtp,
    handleResendOtp,
    forgetPassword,
    sendResetPassCode,
    isApiLoading,
  };
};
