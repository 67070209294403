import { Link } from "react-router-dom";

export const config = {
    apiUrl: process.env.REACT_APP_API_HOST,
    clientCredentialInternal: process.env.REACT_APP_CLIENT_CREDENTIALS_INTERNAL,
    secretKey: process.env.REACT_APP_API_SECRET_KEY,
    clientCredentialMobile: process.env.REACT_APP_CLIENT_CREDENTIALS_MOBILE,
    epresApiUrl: process.env.REACT_APP_API_EPRESCRIBING_HOST,
    epresSecretKey: process.env.REACT_APP_EPRESCRIBING_SECRET_KEY
};

export const grantType = {
    CLIENT_CREDENTIALS: 'na'
};

export const storageKey = {
    accessToken: 'oldst_accesstoken',
    sidebarState: 'sidebarState'
};

export const genderStatus = [{ id: 1, name: 'Male' }, { id: 2, name: 'Female' }];

export const COLLAPSE_ITEMS = [
    {
        key: '1',
        label: 'Is the Prescriber app free to use?',
        children: (
            <p>{'Yes. Prescriber is free for all healthcare providers in the US.'}</p>
        ),
    },
    {
        key: '2',
        label: 'Is the Prescriber app HIPAA complaint?',
        children: (
            <p>
                {
                    'Yes. We require our users to sign a BAA which allows us to act as their Business Associate and deliver the prescription to the pharmacy on their behalf.'
                }
            </p>
        ),
    },
    {
        key: '3',
        label:
            'Can I send prescriptions to mail order pharmacies and specialty pharmacies?',
        children: (
            <p>
                {
                    'The Prescriber app supports a large variety of mail order pharmacies (including Amazon Pharmacy and Mark Cuban Cost Plus Drugs) as well as many specialty pharmacies.'
                }
            </p>
        ),
    },
    {
        key: '4',
        label: 'Can I prescribe controlled substances using the Prescriber app?',
        children: (
            <p>
                {
                    'If you have a DEA number, you will be able to prescriber controlled substances using the Prescriber app.'
                }
            </p>
        ),
    },
    {
        key: '5',
        label: 'Does Docupdate work on any phone?',
        children: (
            <p>{`Yes, we work with all mobile devices. If it can message, we'll always respond.`}</p>
        ),
    },
    {
        key: '6',
        label: 'How do I change my messaging preferences?',
        children: (
            <p>Our goal is to never spam and to make it easy to opt out from any piece of information or all information as a whole. <Link to={"https://www.docupdate.io/manage-preferences"} target="_blank">Click here</Link> to change your preferences.</p>
        ),
    },
    {
        key: '7',
        label: 'Is there any cost to this service?',
        children: (
            <p>{`Docupdate is free and will always remain free for healthcare providers.`}</p>
        ),
    },
    {
        key: '8',
        label: 'Who can sign up for Docupdate?',
        children: (
            <p>{`Docupdate is open to healthcare providers in the US including MDs, PAs, NPs, RNs, DDSs and more.`}</p>
        ),
    },
];
