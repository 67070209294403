import { Input, Select, DatePicker, AutoComplete, TimePicker, InputNumber } from "antd";
import "./floatInput.modules.css";
import { TFloatInputProps } from "../../types/global.types";
import { ReactComponent as EyeOpenOutlined } from '../../assets/eye-open.svg';
import { ReactComponent as EyeClosedOutlined } from '../../assets/eye-close.svg';

const FloatInput: React.FC<TFloatInputProps> = (props) => {
  const { TextArea } = Input;

  const {
    label,
    value,
    placeholder,
    type,
    required,
    selectOptions,
    name,
    disabledDate,
    options,
    controls,
    suffix,
    rows,
    maxLength,
    min,
    max,
    inputWidth,
    disabled,
    format,
    onChange,
    step,
    addonBefore,
    showCount,
    dir,
    id,
    popupText,
    mode,
  } = props;

  const inputPlaceholder = placeholder || label;

  const labelClass = "label as-label";
  const requiredMark = required ? (
    <span className="text-danger" style={{ color: "red" }}>
      *
    </span>
  ) : null;

  return (
    <div
      className="float-label">
      {/* Input Field */}
      {type === "text" && (
        <Input
          onChange={onChange}
          suffix={suffix}
          className="input-component "
          name={name}
          value={value}
          maxLength={maxLength}
          disabled={disabled}
          placeholder={inputPlaceholder}
          style={{ width: inputWidth }}
          showCount={showCount}
          id={id}
          dir={dir}
        />
      )}

      {type === "number" && (
        <InputNumber
          onChange={onChange}
          suffix={suffix}
          value={value}
          min={min}
          max={max}
          step={step}
          maxLength={maxLength}
          disabled={disabled}
          controls={controls}
          placeholder={inputPlaceholder}
          style={{ width: inputWidth }}
          addonBefore={addonBefore}
          className="input-component"
        />
      )}

      {type === "password" && (
        <Input.Password
          className="input-component"
          onChange={onChange}
          value={value}
          maxLength={maxLength}
          placeholder={inputPlaceholder}
          suffix={suffix}
          iconRender={(visible: boolean) => visible ? <EyeOpenOutlined className="w-full object-contain" /> : <EyeClosedOutlined className="w-full object-contain" />}
        />
      )}

      {/* Select Component */}
      {type === "select" && (
        <Select
          onChange={onChange}
          value={value}
          disabled={disabled}
          placeholder={inputPlaceholder}
          style={{ width: inputWidth }}
          mode={mode}
        >
          {selectOptions}
        </Select>
      )}

      {type === "searchselect" && (
        <Select
          showSearch
          allowClear
          onChange={onChange}
          value={value}
          options={options}
          disabled={disabled}
          placeholder={inputPlaceholder}
          style={{ width: inputWidth }}
        />
      )}

      {/* DatePicker Component */}
      {type === "datePicker" && (
        <DatePicker
          onChange={onChange}
          value={value}
          disabledDate={disabledDate}
          disabled={disabled}
          style={{ width: "100%" }}
          inputReadOnly={true}
          placeholder={inputPlaceholder}
        />
      )}

      {/* TimePicker Component */}
      {type === "timePicker" && (
        <TimePicker
          onChange={onChange}
          value={value}
          disabled={disabled}
          use12Hours
          format={format}
          style={{ width: "100%" }}
          inputReadOnly={true}
        />
      )}

      {/* AutoComplete Component */}
      {type === "autoComplete" && (
        <AutoComplete
          options={options}
          value={value}
          onChange={onChange}
          maxLength={maxLength}
        />
      )}

      {/* TextArea Component */}
      {type === "textArea" && (
        <TextArea
          value={value}
          name={name}
          rows={rows}
          onChange={onChange}
          maxLength={maxLength}
          placeholder={placeholder}
          className="input-component"
        />
      )}

      {/* Label */}
      {label && (
        <label className={labelClass} style={{ marginTop: "8px" }}>
          {label} {requiredMark}
        </label>
      )}
      {popupText && (popupText)}
    </div>
  );
};

export default FloatInput;
