// Convert data URL to file
export const dataURLtoFile = (dataurl: string, filename: string) => {
  const arr = dataurl.split(",");
  const mime = arr[0]?.match(/:(.*?);/)?.[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
};

export function getProfileInitials(firstName: string, lastName: string) {
  const capitalize = (char: string) => char.charAt(0).toUpperCase();
  const firstInitial = capitalize(firstName);
  const lastInitial = capitalize(lastName);

  const initials = `${firstInitial}${lastInitial}`;

  return {
    initials,
  };
}

export const calculateFontSize = (name: string) => {
  if (name.length <= 15) return "20px"; // Default size
  if (name.length <= 25) return "16px"; // Smaller for medium-length names
  return "14px"; // Smallest for very long names
};

export const checkPhoneNumber = (
  initialPhoneNumber: string,
  currentPhoneNumber: string
) => {
  if (initialPhoneNumber[0] === "+") {
    return currentPhoneNumber[0] === "+"
      ? currentPhoneNumber
      : `+${currentPhoneNumber}`;
  }
  return currentPhoneNumber;
};
